@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:wght@400;700&display=swap");

#secretBeautyBanner1 {
  background-image: url("../imgs/SecretBeauty/banner1.jpeg");
  height: 42vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 10px 45px 10px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

#secretBeautyBanner1 h1,
#secretBeautyBanner2 h1 {
  font-family: "DM Serif Display", "Arial";
  color: #000000;
  text-shadow: 2px 0px 0px RGBA(0, 0, 0, 1);
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: inherit;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  font-weight: 400;
}

#secretBeautyBanner1 h3,
#secretBeautyBanner2 h5 {
  font-family: "Playfair Display", "Arial";
  color: #000000;
  text-shadow: 1px 0px 0px RGBA(0, 0, 0, 1);
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: inherit;
  letter-spacing: 2px;
  word-spacing: normal;
  text-transform: none;
  font-weight: 600;
}

#img1 {
  background-image: url("../imgs/SecretBeauty/img1.jpeg");
  height: max-content;
  flex: 1;
  background-size: cover;
  height: 540px;
  display: flex;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 10%;
  background-position: center;
}

#img1 h5 {
  width: 60%;
  text-align: center;
  font-weight: 900;
  font-size: 25px;
  color: white;
}

#img2Section {
  flex: 1;
}

#secretBeautySection1 {
  margin: 5% 15%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: rgba(250, 235, 215, 0.349);
  padding: 3%;
}

.img2SectionImgs {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 2%;
}

#secretBeautyBanner2 {
  background-image: url("../imgs/SecretBeauty/banner2.jpg");
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  background-size: cover;
  padding: 10%;
}

#img6 {
  background-image: url("../imgs/SecretBeauty/img6.jpeg");
  height: max-content;
  flex: 1;
  background-size: cover;
  height: 130vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

#img6 h5 {
  color: white;
  width: 40%;
  font-size: 25px;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

#secretBeautySection2 {
  padding: 5%;
}

@media only screen and (max-width: 1200px) {
  #secretBeautySection1 {
    flex-direction: column;
    margin: 5% 10%;
  }
  .img2SectionImgs {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  #secretBeautyBanner1,
  #secretBeautyBanner2 {
    padding: 20px 10px;
  }
  #secretBeautySection1,
  #secretBeautySection2 {
    margin: 5% 2%;
  }
  #img1,
  #img6 {
    height: 400px;
  }
  #img6 h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  #secretBeautyBanner1,
  #secretBeautyBanner2 {
    height: 30vh;
  }
  #img1,
  #img6 {
    height: 300px;
  }
  #img1 h5,
  #img6 h5 {
    font-size: 18px;
  }
  .img2SectionImgs img {
    width: 100%;
    height: auto;
  }
}
