.product-card {
  margin-bottom: 30px;
}

.product-card .card-img-top {
  height: 100%;
  width: 90%;
  object-fit: cover;
}

.product-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.product-card .card-text {
  font-size: 1rem;
  color: #6c757d;
}

.product-card .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.card {
  border: none !important;
  transition: transform 0.3s ease;
}
.card:hover {
  /* opacity: 0.8; */
  transform: scale(1.05) translateZ(10px);
}
