.cartItems {
  display: flex;
  align-items: center !important;
  justify-content: center;
  gap: 2%;
  flex-direction: row;
}
.cartItems h4 {
  font-size: 20px;
}
.cartItems h3 {
  font-size: 22px;
}

.itemButtons {
  padding: 5px 10px;
  margin: 0px 10px;
  background-color: #1346af;
  border-radius: 10px;
}
.itemButtonsinProducts {
  font-size: 28px;
  padding: 5px 15px;
  margin: 0px 10px;
  background-color: #1346af;
  border-radius: 10px;
}

#checkoutButton {
  display: flex;
  justify-content: center;
}

.itemsInput {
  border: none;
  font-weight: 600 !important;
  text-align: center;
}
.itemsInputinProducts {
  border: none;
  font-size: 20px;
  font-weight: 600 !important;
  text-align: center;
}
@media (max-width: 575.98px) {
  .cartItems {
    display: flex;
    align-items: center !important;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .cartItems h4 {
    font-size: 20px;
  }
  .cartItems h3 {
    font-size: 22px;
  }
  .itemButtonsinProducts {
    font-size: 18px;
    padding: 5px 10px;
    margin: 0px 10px;
    background-color: #1346af;
    border-radius: 10px;
  }
  /* src/assets/Styles/Cart.css */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 60px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}
}

.termOfSales h5 {
  text-align: start !important;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 60px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}

.popup.active {
  display: block;
}
.popup label {
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.active {
  display: block;
}

.popup input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  border: 1px solid black;
}

.popup button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
}
