.special-offers-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-offers-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3%;
  width: 60%;
  gap: 10%;
  padding: 3%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.special-offers-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 575.98px) {
  .special-offers-card {
    zoom: 40%;
    width: 100%;
  }.special-offers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
}
