#productSection1 {
  display: flex;
  justify-content: center;
  align-items: start;
}

#productSection1 div {
  flex: 1;
  text-align: start;
}

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  width: 70%;
}

.menu button {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.content p {
  text-align: start;
  width: 100%;
}
.content strong {
  text-align: center;
  width: 100%;
}
@media (max-width: 575.98px) {
  #productSection1 {
    flex-direction: column;
    padding: 0px 10px;
  }
  #productSection1 div h2 {
    font-size: 28px !important;
  }
  #productSection1 div h4 {
    font-size: 16px;
  }
  .menu {
    flex-direction: column;
  }
  .content {
    width: 100%;
    text-align: center;
  }
  .content p {
    width: 100%;
    text-align: center;
  }
}
.carousel-container {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 15px;
}

.carousel-image {
  height: auto;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.229);
  border-radius: 10px;
  margin: 10px;
}
.carousel-control-next-icon:hover,
.carousel-control-prev-icon:hover {
  background-color: rgba(0, 0, 0, 0.444);

  border-radius: 10px;
  margin: 10px;
}
