#espressoRangeBanner {
  background-image: url("../../imgs/Ranges/espressoRange/espresso-range-banner.jpg");
  background-position: end;
  height: 90vh;
}
#espressoRangeBanner2 {
  margin-top: 3%;
  background-image: url("../../imgs/Ranges/espressoRange/espresso-range-banner-2.jpg");
  background-position: center;
  height: 100vh;
}
#espressoRangeBanner3 {
  margin-top: 3%;
  background-image: url("../../imgs/Ranges/espressoRange/3rd-banner.jpg");
  background-position: center;
  background-size: contain;
  height: 100vh;
}
#espressoRangeBanner h1 {
  font-size: 58px;
  padding-top: 4%;
  font-weight: 600;
}

a {
  text-decoration: none;
  font-size: large;
  color: grey;
}

#espressoRangeBanner {
  text-align: center;
}

#espressoRangeBanner h1,
#espressoRangeBanner h4 {
  text-align: center;
}

#espressoRangeBanner2 {
  padding-left: 10%;
  padding-top: 5%;
}

#espressoRangeBanner2 h1 {
  font-size: 78px;
  width: 35%;
}

#espressoRangeBanner2 h4 {
  font-size: 40px;
  width: 35%;
}

#espressoRangeBanner3 {
  padding-top: 5%;
}

#espressoRangeBanner3 h1 {
  font-size: 120px;
  text-align: center;
}

.center-text {
  text-align: center;
}

.margin-3 {
  margin: 3%;
}

.margin-0 {
  margin: 0;
}

.margin-3-0 {
  margin: 3% 0%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.gap-10 {
  gap: 10px;
}

.width-100 {
  width: 100%;
}

@media (max-width: 575.98px) {
  .flex-center {
    flex-direction: column;
  }
  #espressoRangeBanner h1 {
    font-size: 28px;
    padding-top: 4%;
    font-weight: 600;
  }
  #espressoRangeBanner h4 {
    font-size: 16px;
    font-weight: 400;
  }
  #espressoRangeBanner {
    background-image: url("../../imgs/Ranges/espressoRange/espresso-range-banner.jpg");
    background-position: center;
    height: 40vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .titleSection {
    margin-top: 10%;
  }
  .textSection {
    font-weight: 400;
  }
  .espressoRangeSection1sub {
    font-weight: 400;
    font-size: 20px;
  }
  .width-40 {
    width: 80%;
  }
  .width-50 {
    width: 100%;
  }
  #espressoRangeBanner2 div h1,#espressoRangeBanner3 h1{
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  #espressoRangeBanner2 div h4,#espressoRangeBanner3 div h4{
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
  #espressoRangeBanner2, #espressoRangeBanner3{
    height: 40vh;
    background-size: cover;
  }
  .espressoRangeBanner3h4{
    font-size: 24px !important;
  }
}
.textNormal {
  font-size: 20px;
  font-weight: 400;
}

.espressoRangeSection1sub{
  font-weight: 400;
}

*{
  font-weight: 400 !important;
}