.homeBannerDiv {
  display: "block";
  width: "100%";
  height: "80vh";
}
.homeBanner {
  display: "block";
  width: "100%";
  height: "80vh";
}

.banner1 {
  width: 100%;
}
.custom-overlay {
  position: absolute;
  top: 15%;
  color: white;
  background: linear-gradient(
    to right,
    rgba(217, 214, 212, 1),
    rgba(217, 214, 212, 0)
  ); /* Gradient from solid to transparent */
  padding: 1% 8%;
  border-radius: 0px; /* Adjust as needed */
}

.custom-overlay h2 {
  margin: 0;
  font-size: 2.5rem;
}

.custom-overlay p {
  color: white;
  margin: 0;
  font-size: 1.5rem;
}

.custom-overlay button {
  margin-left: 2%;
  padding: 5px 10px;
  font-size: 1rem;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid grey;
}

.custom-overlay button:hover {
  background-color: #f0f0f0; /* Optional: Add a hover effect */
}

.sectionHeadH {
  font-size: 58px;
}
.sectionHeadP {
  font-size: 29px;
  color: #474747;
}

.slick-slide img {
  width: 100%;
  gap: 10px;
}

.slick-slide {
  margin: 0 20px;
}

.sliderDiv {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  margin-bottom: 2%;
}

.brandUniverseImg {
  object-fit: cover !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.brandUniverseH {
  font-weight: 500;
  margin-top: 2%;
  margin-left: 15%;
  text-align: end;
  margin-right: 6%;
}
.brandUniverseP {
  text-align: end;
  font-weight: 500;
  margin-left: 15%;
  margin-right: 6%;
}
.CoffeeTherapyH {
  font-weight: 500;
  text-align: start;
  margin-left: 6%;
}
.followUsH {
  font-weight: 500;
  text-align: center;
}
.CoffeeTherapyP {
  font-weight: 500;
  text-align: start;
  margin-left: 6%;
}

#sliderDiv3 {
  width: 50% !important;
  max-height: 400px;
  overflow: hidden;
  margin-bottom: 2%;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered a img {
  width: 10%;
}
.centered a {
  width: 20%;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 10%;
  background-color: #eeeeec;
  padding: 20px 40px;
  color: #8f8f8f;
}
.footer h2 {
  color: #8f8f8f;
}

.footer div h4,
.footer div h4 a {
  text-decoration: none;
  color: #8f8f8f;
}
.footer div h4 a:hover {
  color: #1346af;
}

.socialMedia {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.homeDiv {
  background-color: white;
  z-index: 1000;
}

@media (max-width: 575.98px) {
  .footer {
    flex-direction: column;
    zoom: 70%;
  }
  .socialMedia {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  #followUsImg {
    width: 70%;
  }
  .custom-overlay h2 {
    margin: 0;
    font-size: 18px;
  }

  .custom-overlay p {
    color: white;
    margin: 0;
    font-size: 10px;
  }

  .custom-overlay button {
    font-size: 10px;
    margin-left: 15%;
  }
  .brandUniverseH {
    font-weight: 500;
    margin-top: 2%;
    margin-left: 0%;
    font-size: 28px;
    text-align: center;
  }
  .followUsH {
    font-weight: 500;
    margin-top: 2%;
    margin-left: 0%;
    font-size: 28px;
    text-align: center;
  }
  .brandUniverseP {
    text-align: center;
    font-size: 16px;
    margin-left: 0px;
  }
  .sliderDiv,
  #sliderDiv3 {
    height: 13vh;
  }
  .CoffeeTherapyH {
    font-size: 28px;
    text-align: center;
    margin-right: 0;
  }
  .CoffeeTherapyP {
    font-size: 16px;
    text-align: center;
    margin-right: 0;
  }
  .footerh4 {
    font-weight: 900;
  }
}
