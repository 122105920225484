@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:wght@400;700&display=swap");

#menuImg {
  margin-top: 2%;
  width: 15%;
}

header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  background: #000;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  color: #fff;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
}

.menuDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  position: relative;
  margin-bottom: 2%;
}

.menuItems {
  display: flex;
}

.menuItems a {
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  position: relative;
  font-family: "Playfair display";
}

.dropdown,
.nested-dropdown {
  position: relative;
}
.nested-dropdown {
  margin: 3%;
}
.dropdown-content,
.nested-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown:hover > .dropdown-content,
.dropdown-content:hover,
.nested-dropdown:hover > .nested-dropdown-content,
.nested-dropdown-content:hover {
  display: block;
}
.dropdown:hover > .dropdown-content {
  margin: 5%;
  box-shadow: 0px 0px 10px black;
  border-radius: 5px;
}
.nested-dropdown:hover > .nested-dropdown-content {
  box-shadow: 0px 0px 10px black;
  border-radius: 5px;
}
.dropdown-content a,
.nested-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-content a:hover,
.nested-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.nested-dropdown-content {
  left: 100%;
  top: 0;
}

.languageSelect {
  margin-top: 20px;
  position: absolute;
  right: 5%;
  top: 50%;
}

@media (max-width: 1047px) {
  .menuItems {
    display: none;
  }
  .languageSelect {
    top: 0%;
  }
  #menuImg {
    width: 30%;
  }
  .hamburger-menu {
    display: block !important;
  }
}
.hamburger-menu {
  display: none;
}
.hamburger-menu {
  cursor: pointer;
}

/* Mobile menu styles */
.mobileMenuDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  padding-top: 0px;
  z-index: 2000;
  overflow-y: auto;
  transition: transform 0.3s ease;
}

/* Close button styles */
.close-btn {
  text-align: end;
  font-size: 45px;
  font-weight: bold;
  cursor: pointer;
}

/* Mobile menu list styles */
.mobileMenuDiv ul {
  list-style-type: none;
  padding: 0;
}

.mobileMenuDiv li {
  margin: 15px 0;
  font-size: 18px;
  cursor: pointer;
}

#MobileMenu {
  text-align: center;
}

.mobileMenuDiv ul {
  list-style-type: none;
  padding: 0;
}

.mobileMenuDiv li {
  margin: 15px 0;
  font-size: 18px;
  cursor: pointer;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-title {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 200px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content li {
  padding: 10px;
}
.submenu ul li a {
  margin-left: 10%;
}

.subsubmenu {
  margin-left: 10px;
}
.subsubmenu ul li {
  margin-left: 10px;
}
