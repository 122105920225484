.macchiatoRangeBanner {
  background-image: url("../../imgs/Ranges/macchiatoRange/banner.jpg");
  background-position: end;
  background-size: cover;
  height: 90vh;
}

#section1 {
  background-image: url("../../imgs/Ranges/macchiatoRange/section.jpg");
  background-position: end;
  background-size: cover;
  height: 80vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3%;
  margin-top: 3%;
}
.section1Text {
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#section1 p {
  text-align: center;
  width: 70%;
}

#macchiatoRangeBanner2 {
  background-image: url("../../imgs/Ranges/macchiatoRange/banner2.jpg");
  background-position: end;
  background-size: cover;
  height: 90vh;
}
.macchiatoBanner2 {
  margin-top: 10%;
  gap: 2%;
}
.centerColumn3 {
  text-align: center;
  padding: 3% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
  background-color: #d5d5d587;
}
.centerColumn3 p {
    text-align: center;
   font-size: 18px;
  }
.centerColumn3 button {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 14px;
}
.macchiatoRangeBanner3 {
    background-image: url("../../imgs/Ranges/macchiatoRange/banner3.jpg");
    background-position: end;
    background-size: cover;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: end;
    font-size: 65px;
  }

  @media (max-width: 575.98px) {
    .macchiatoRangeBanner{
      height: 20vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    #section1{
      width: 100%;
    }
    .centerColumn3{
      width: 80%;
    }
    #macchiatoRangeBanner2 h1 {
      padding-top: 5%;
    }
    #macchiatoRangeBanner2 {
      height: 60vh;
      background-position: center;
      background-size: cover;
    }
    .macchiatoRangeBanner3{
      height: 40vh;
      font-size: 38px;
    }
  }