#ourHistory {
  background-image: url("../imgs/BrandUniverse/ourHistory.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 3%;
}
#ourHistory div p {
  font-size: 18px;
}
#ourHistory div {
  width: 50%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#ourHistory h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}

#Simplicity {
  background-image: url("../imgs/BrandUniverse/simplicity.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6%;
}

#Simplicity div p {
  font-size: 18px;
}
#Simplicity div {
  width: 50%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#Simplicity h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}

#TheCoffeeBean {
  background-image: url("../imgs/BrandUniverse/coffeeBean.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 3%;
}

#TheCoffeeBean div p {
  font-size: 18px;
}
#TheCoffeeBean div p ul li {
  font-weight: 600;
}
#TheCoffeeBean div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#TheCoffeeBean h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#research {
  background-image: url("../imgs/BrandUniverse/research.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 3%;
}

#research div p {
  font-size: 18px;
}
#research div p ul li {
  font-weight: 600;
}
#research div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#research h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#caffoetherapy {
  background-image: url("../imgs/BrandUniverse/caffoetherapy.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#caffoetherapy div p {
  font-size: 18px;
}
#caffoetherapy div p ul li {
  font-weight: 600;
}
#caffoetherapy div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#caffoetherapy h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#friendly {
  background-image: url("../imgs/BrandUniverse/friendly.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#friendly div p {
  font-size: 18px;
}
#friendly div p ul li {
  font-weight: 600;
}
#friendly div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#friendly h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#package {
  background-image: url("../imgs/BrandUniverse/package.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#package div p {
  font-size: 18px;
}
#package div p ul li {
  font-weight: 600;
}
#package div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#package h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#values {
  background-image: url("../imgs/BrandUniverse/values.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#values div p {
  font-size: 18px;
}
#values div p ul li {
  font-weight: 600;
}
#values div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#values h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#ingredients {
  background-image: url("../imgs/BrandUniverse/ingredients.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#ingredients div p {
  font-size: 18px;
}
#ingredients div p ul li {
  font-weight: 600;
}
#ingredients div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#ingredients h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}
#products {
  background-image: url("../imgs/BrandUniverse/products.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 6%;
}

#products div p {
  font-size: 18px;
}
#products div p ul li {
  font-weight: 600;
}
#products div {
  width: 60%;
  background-color: #ebe4ded9;
  line-height: 250%;
  padding: 20px;
}

#products h1 {
  color: white;
  font-size: 80px;
  font-weight: 900;
}

@media (max-width: 575.98px) {
  #ourHistory div,
  #Simplicity div,
  #TheCoffeeBean div,
  #research div,
  #caffoetherapy div,
  #friendly div,
  #package div,
  #values div,
  #ingredients div,
  #products div {
    width: 100%;
  }
  #ourHistory h1,
  #Simplicity h1,
  #TheCoffeeBean h1,
  #research h1,
  #caffoetherapy h1,
  #friendly h1,
  #package h1,
  #values h1,
  #ingredients h1,
  #products h1 {
    font-size: 40px;
  }
}
 div p b{
  font-weight: 600 !important;
}