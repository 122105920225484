@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: none;
  line-height: 1;
  color: #2b2b2b;
}

.banner {
  padding: 1%;
  height: 90vh;
  background-position: center;
  text-align: start;
  background-color: #ffffffb8;
  /* margin: 1%; */
  padding: 2%;
}

.banner h1 {
  font-size: 60px;
  text-align: start;
  background-color: #ffffffb8;
  margin: 1%;
  padding: 2%;
}

.banner-cover {
  background-size: cover;
}

.banner-contain {
  background-size: contain;
}

.heading1 {
  margin: 3%;
  text-align: end;
  font-size: 40px;
}

.heading2 {
  margin: 3%;
  text-align: end;
  font-size: 30px;
}

.paragraph {
  margin: 3%;
  text-align: end;
}

.large-heading {
  margin: 4%;
  text-align: end;
  font-size: 30px;
}

.large-paragraph {
  margin: 4%;
  text-align: end;
  font-size: 18px;
}

#bodyCareBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/bodycare.jpg");
}

#ExfoliatorBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/exfoliators.jpg");
}

#eyeLipBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/eyelip.jpg");
}

#faceCreamsBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/face.jpg");
}

#lighteningBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/lightening.jpg");
}

#MakeUpBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/banner.jpg");
}

#MasksBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/exfoliators.jpg");
}

#serumBanner {
  background-size: cover;
  background-image: url("../imgs/Categories/serum.jpg");
}

#AntiAgingBanner,
#PimplesBanner,
#DarkCirclesBanner,
#NourishmentBanner,
#OilySkinBanner,
#DehydratedSkinBanner,
#DullComplexionBanner,
#NormalCombinationSkinBanner,
#PigmentationsBanner,
#RednessBanner,
#StretchMarksBanner {
  padding: 1%;
  height: 90vh;
  background-position: center;
  background-size: cover;
}

/* Specific banner backgrounds */
#AntiAgingBanner {
  background-size: cover;
  background-image: url("../imgs/Need/banner.jpg");
}

#PimplesBanner {
  background-size: cover;
  background-image: url("../imgs/Need/pimples.jpg");
}

#DarkCirclesBanner {
  background-size: cover;
  background-image: url("../imgs/Need/darkcircles.jpg");
}

#NourishmentBanner {
  background-size: cover;
  background-image: url("../imgs/Need/Nourishment.jpg");
}

#OilySkinBanner {
  background-size: cover;
  background-image: url("../imgs/Need/OilySkin.jpg");
}

#DehydratedSkinBanner {
  background-size: cover;
  background-image: url("../imgs/Need/DehydratedSkin.jpg");
}

#DullComplexionBanner {
  background-size: cover;
  background-image: url("../imgs/Need/DullComplexion.jpg");
}

#NormalCombinationSkinBanner {
  background-size: cover;
  background-image: url("../imgs/Need/NormalCombinationSkin.jpg");
}

#PigmentationsBanner {
  background-size: cover;
  background-image: url("../imgs/Need/Pigmintation.jpg");
}

#RednessBanner {
  background-size: cover;
  background-image: url("../imgs/Need/redness.jpg");
}

#StretchMarksBanner {
  background-size: cover;
  background-image: url("../imgs/Need/StretchMarks.jpg");
}

@media (max-width: 575.98px) {
  #MakeUpBanner,
  #ExfoliatorBanner,
  #MasksBanner,
  #serumBanner,
  #eyeLipBanner,
  #bodyCareBanner,
  #lighteningBanner,
  #faceCreamsBanner,
  #AntiAgingBanner,
  #PimplesBanner,
  #DarkCirclesBanner,
  #NourishmentBanner,
  #OilySkinBanner,
  #DehydratedSkinBanner,
  #DullComplexionBanner,
  #NormalCombinationSkinBanner,
  #PigmentationsBanner,
  #RednessBanner,
  #StretchMarksBanner {
    height: 35vh;
    width: 100%;
    /* background-size: contain; */
    background-position: center;
  }
  .heading1 {
    text-align: center;
    font-size: 20px;
  }
  .heading2 {
    text-align: center;
    font-size: 16px;
  }
  .banner h1 {
    font-size: 30px;
  }
  .paragraph {
    text-align: center;
  }
}
