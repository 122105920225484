#mokaRangeBanner {
  background-image: url("../../imgs/Ranges/mokaRange/banner.jpg");
  background-position: end;
  background-size: cover;
  height: 90vh;
}
#mokaRangeBanner h1 {
  padding-top: 3%;
  font-size: 50px;
  font-weight: 600;
}

#mokaRangeBanner h2 {
  font-size: 34px;
}
#mokaRangeBanner h4 {
  font-size: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

#mokaRangeSection2 {
  background-image: url("../../imgs/Ranges/mokaRange/section.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3%;
  width: 85%;
  padding: 2%;
}

.mokaRangeSection2Text {
  background-color: #aba7a79e;
  width: 50%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: end;
  margin-bottom: 2%;
}

#mokaRangeBanner2 {
  background-image: url("../../imgs/Ranges/mokaRange/banner2.jpg");
  background-position: center;
  background-size: contain;
  height: 90vh;
}
#mokaRangeBanner2 h1 {
  padding-top: 3%;
}

.centerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerColumn p {
  width: 40%;
  background-color: #e4e4e4;
  text-align: center;
  font-size: 18px;
  padding: 1% 3%;
}
.centerColumn a button {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 14px;
}

.centerColumn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
}
.centerColumn2 p {
  text-align: center;
}

.gap {
  gap: 10px;
}

#mokaRangeBanner3 {
  background-image: url("../../imgs/Ranges/mokaRange/banner21.jpg");
  background-position: end;
  background-size: cover;
  height: 90vh;
}

@media (max-width: 575.98px) {
  #mokaRangeBanner {
    background-image: url("../../imgs/Ranges/mokaRange/banner.jpg");
    background-position: center;
    background-size: cover;
    height: 40vh;
  }
  #mokaRangeBanner h1 {
    padding-top: 3%;
    font-size: 28px;
    font-weight: 600;
  }

  #mokaRangeBanner h2 {
    font-size: 16px;
  }
  #mokaRangeBanner h4 {
    font-size: 18px;
    display: none;
  }
  .mokaRangeSection2Text {
    width: 100%;
  }
  #mokaRangeBanner2 div div p {
    font-size: 16px;
  }
  .centerColumn p {
    width: 70%;
  }
  #mokaRangeSection2{
    width: 100%;
  }
  #mokaRangeBanner2{
    height: 40vh;
    background-size: cover;
  }
  .center{
    flex-direction: column;
  }.centerColumn2{
    width: 80%;
  }
  img{
  width: 100%;
    object-fit: cover;
  }
  #mokaRangeBanner3{
    height: 40vh;
    background-size: cover;
    background-position: center;
  }
  .product-card .card-img-top{
    width: 100%;
  }
}
